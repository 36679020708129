<template>
  <div class="product">
    <div class="hdjl">
      <div class="hdjl_box">
        <p>互动交流</p>
        <p>构建同一张网上的信息文件安全共享交流平台</p>
        <p>个人、单位之间信息、文件材料的快捷安全共享</p>
        <el-button>在线咨询</el-button>
      </div>
    </div>
    <div class="hxld">
      <p>核心亮点</p>
      <div class="hxld_box">
        <img class="img" id="img" src="@/assets/images/product/hdjl/hxld.png" />
        <div class="hxld_box_item" id="title">
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/hdjl/hxld_icon1.png"
            />
            <p class="first_line">全网发送</p>
            <p class="second_line">单位和个人之间<br />均可通过系统发送便函</p>
          </div>
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/hdjl/hxld_icon2.png"
            />
            <p class="first_line">一键转发</p>
            <p class="second_line">用户收到便函后可一键转发，<br />快速便捷</p>
          </div>
        </div>
      </div>
    </div>
    <div class="jjtx">
      <div class="jjtx_box">
        <div class="jjtx_box_item" id="title2">
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/hdjl/jjtx_icon1.png"
            />
            <p class="first_line">快速回复</p>
            <p class="second_line">用户收到便函后<br />可即时回复发送人</p>
          </div>
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/hdjl/jjtx_icon2.png"
            />
            <p class="first_line">紧急提醒</p>
            <p class="second_line">
              文件按特提、特急、急、平分类。<br />根据紧急程度，自动短信提醒。
            </p>
          </div>
        </div>
        <img
          class="img"
          id="img2"
          src="@/assets/images/product/hdjl/jjtx.png"
        />
      </div>
    </div>
    <div class="cygz">
      <img class="img" id="img3" src="@/assets/images/product/hdjl/cygz.png" />
      <div class="cygz_box_item" id="title3">
        <div class="title">
          <img class="icon" src="@/assets/images/product/hdjl/cygz_icon1.png" />
          <p class="first_line">查阅跟踪</p>
          <p class="second_line">便函发送后<br />可跟踪接收人的接收情况</p>
        </div>
        <div class="title">
          <img class="icon" src="@/assets/images/product/hdjl/cygz_icon2.png" />
          <p class="first_line">规范安全</p>
          <p class="second_line">便函发送接收在<br />同一张网完成安全保密</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data () {
    return {

    }
  },
  mounted () {
    $(window).scroll(this.scrollBar)
  },
  methods: {
    scrollBar () {
      const h = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      var hxldH = $('.hxld').offset().top
      var jjtxH = $('.jjtx').offset().top
      var cygzH = $('.cygz').offset().top
      if (
        h > hxldH - 500
      ) {
        $('#img').addClass('animation')
      }
      if (h > jjtxH - 500) {
        $('#img2').addClass('animation')
      }
      if (
        h > cygzH - 500
      ) {
        $('#img3').addClass('animation')
      }
    }
  },
  destroyed () {
    $(window).unbind('scroll', this.scrollBar)
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
</style>
